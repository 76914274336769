import React from 'react';
import '../assets/scss/index.scss';
import Acnavbar from '../components/Acnavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../components/footer';
import ProductDescription from '../components/ProductDescription';
import call from '../assets/images/callWhite.webp';
import ld from '../assets/images/linkedinWhite.webp';
import twitter from '../assets/images/twitterIcon.webp';
import { Container } from 'react-bootstrap';
import worldmap from '../assets/images/world-map.svg';
import email from '../assets/images/email-contact.webp';
import Seo from '../shared/seo';
import Wrapper from '../components/Wrapper';
import 'react-phone-input-2/lib/style.css';
import ContactUsForm from '../components/ContactUsForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function Contact() {
  const reCaptchaKey: any = process.env.RECAPTCHA_SITE_KEY;
  return (
    <GoogleReCaptchaProvider children={true} reCaptchaKey={reCaptchaKey}>
      <Wrapper>
        <Seo
          title='Contact Softobotics: Reach Out for IT Solutions'
          description='Connect with Softobotics for IT needs and partnerships. Experience our innovative solutions for business success.'
        />
        <div className='contact-page'>
          <Acnavbar home={false} pathname={'contact'} logo={''} logoLogic={false} />
          <ProductDescription
            header='Contact Us'
            headerText='We would love to hear from you!'
            showButton={false}
            className={true}
          />

          <div className=' d-flex flex-column align-items-center justify-content-center contact-form-section'>
            <div className='form-section  m-lg-4 m-3 p-3 p-lg-5 d-flex flex-column justify-content-center  w-lg-60 w-xxl-50'>
              <ContactUsForm />
            </div>
          </div>

          <div className='common-background  p-lg-5'>
            <Container>
              <div className='d-flex flex-column flex-lg-row'>
                <div className='d-flex flex-column align-items-start  col-lg-3 p-3 p-md-0'>
                  <div className='text-white fw-700 fnt-20 fnt-lg-25 mb-3'>Location</div>
                  <div className='d-flex'>
                    <div className='fw-400 fnt-16 fnt-lg-18 lh-28 text-white mb-3 mb-lg-4 '>
                      1st Floor, Prerana Towers, 1st Floor, 563/1, Ranka Colony Rd, Munivenkatppa
                      Layout, Bilekahalli, Bengaluru, Karnataka 560076
                    </div>
                  </div>
                  <div className='fnt-16 fnt-lg-18 location '>
                    <a
                      className='text-white text-underline'
                      target='_blank'
                      href='https://goo.gl/maps/Qkk81CzfJLQZuyJw9'
                    >
                      Find us on Google Maps
                    </a>
                  </div>
                </div>
                <div className='world-map  col-lg-9  mt-3   mt-lg-0 '>
                  <LazyLoadImage
                    effect='blur'
                    className='mb-3 mb-lg-0'
                    src={worldmap}
                    alt='world-map'
                  />
                </div>
              </div>
            </Container>
          </div>

          <div className='contact-us-bottom p-3 py-lg-4'>
            <Container>
              <div className='d-flex flex-column flex-lg-row justify-content-between align-items-lg-center'>
                <div className='d-flex align-items-center mb-1'>
                  <LazyLoadImage
                    effect='blur'
                    className='me-2 img-contact-width'
                    src={email}
                    alt='Email Icon'
                  />
                  <div className='fw-500 fnt-16 fnt-lg-18 text-white  contact-email'>
                    <a href='mailto:contact@softobotics.com'>contact@softobotics.com</a>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-2 '>
                  <LazyLoadImage
                    effect='blur'
                    className='me-2 mt-1 img-contact-width'
                    src={call}
                    alt='Phone Icon'
                  />
                  <div className='fw-500 fnt-16 fnt-lg-18 text-white  contact-call'>
                    <a href='tel:+91803-500-8966'>+91803-500-8966</a>
                  </div>
                </div>

                <div className='d-flex align-items-cente mb-2'>
                  <LazyLoadImage
                    effect='blur'
                    className='me-2 img-contact-width'
                    src={ld}
                    alt='logo'
                  />
                  <div className='fnt-500  fnt-16 fnt-lg-18 text-white '>
                    <a href='https://www.linkedin.com/company/softobotics/' target='_blank'>
                      Linkedin
                    </a>
                  </div>
                </div>

                <div className='d-flex align-items-center me-3'>
                  <LazyLoadImage
                    effect='blur'
                    className='me-2  img-contact-width'
                    src={twitter}
                    alt='Twitter Icon'
                  />
                  <div className='fnt-500  fnt-16 fnt-lg-18 text-white '>
                    <a href='https://twitter.com/SoftoboticsTech/'>Twitter</a>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <Footer />
        </div>
      </Wrapper>
    </GoogleReCaptchaProvider>
  );
}

export default Contact;
